body {
}
header {
  margin-bottom: 70px;
}
.modal-content {
  position: relative;
}
#player {
  padding: 10px;
}
.filmLink,
.spCat {
  cursor: pointer;
}
.embed-responsive .card-img-top {
  object-fit: cover;
  height: 400px;
}
.card-body {
  height: 236px;
}
#installApp {
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: none;
}
#btn-back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 645px;
    margin: 1.75rem auto;
  }
}
